import { html, css, LitElement, PropertyValues } from 'lit';
import { customElement, state } from 'lit/decorators.js';

import type { ClientWWWHydrationData } from '../server-shared/client-www.d.ts';

@customElement('app-client-www')
export class HelloWorld extends LitElement {
    static styles = css`
        :host {
            display: block;
            padding: 16px;
            color: var(--hello-world-text-color, black);
        }

        .container {
            position: absolute;
            top: 66.67%;
            right: 10%;
            transform: translateY(-50%);
            padding: 16px;
        }

        h1 {
            font-family: "Wulkan Display Bold";
        }
    `;

    @state() username?: string;

    firstUpdated() {
        // fetch('https://auth.asunder.co/auth/test-authentication', {
        //     credentials: 'include',
        // }).then(response => {
        //     return response.json();
        // }).then(data => {
        //     console.log('User data:', data);
        //     this.username = data.username || undefined;
        // }).catch(error => {
        //     console.error('Error:', error);
        // });

        // @ts-ignore
        const data = window.__DATA__ as ClientWWWHydrationData;
        if(data.authenticated){
            this.username = data.username;
        }
    }

    render() {
        return html`<div class="container">
            <h1>Asunder Labs</h1>
            <p>A final home for illustrators, designers, and all creatives.</p>
            <p>Building the future of creativity and design tooling.</p>
            <p>Contact: <a href="mailto:info@asunder.co">info@asunder.co</a></p>
            <footer>
                <p>&copy; 2025 Asunder Labs. All rights reserved.</p>
            </footer>
            <br><br>
            <p>${this.username ? `Logged in as: ${this.username}` : ``}</p>
        </div>`;
    }
}